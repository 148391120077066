body {
  background: var(--bui_color_background_base_alt);
}

#root,
body,
html {
  height: 100%;
}

.main {
  height: 100vh;
  position: relative;
  overflow-x: hidden;

  &--with-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("./images/login_bkg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: fadein 1s;
    padding-top: $spacing-largest * 2;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.container {
  max-width: $booking-container-width;
  padding: 0 $spacing-half;
  margin: 0 auto;
}

.static-panel {
  padding: 0;
  line-height: $spacing-double;
  position: relative;
  text-align: center;
  border: 1px solid var(--bui_color_border_alt);
  margin: 0 0 $spacing-half 0;
  line-height: $spacing-double;
  background: var(--bui_color_background_elevation_one);
  box-shadow: $booking-box-shadow-depth-1;
  border-radius: var(--bui_border_radius_100);
}
