@import "./components/carInfo";
@import "./components/carRateInput";
@import "./components/checkboxToggle";
@import "./components/ratesForm";
@import "./components/successModal";
@import "./components/wizard";

@import "./routes/form";

.--booking-container-width {
  max-width: $booking-container-width;
}

.--center-x {
  margin: 0 auto !important;
}

.--center-y {
  margin: auto 0 !important;
}
